import pic1 from './assets/project.png'
const slides = [
    {
      title: "Oleson",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore, facere aperiam libero.",
      photo: pic1
    },
    {
      title: "Oleson",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore, facere aperiam libero.",
      photo: pic1
    },
    {
      title: "Oleson",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore, facere aperiam libero.",
      photo: pic1
    },
    {
      title: "Oleson",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore, facere aperiam libero.",
      photo: pic1
    }
  ]
  export default slides;